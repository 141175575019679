import React from 'react'
import { useTheme } from '~/core/contexts/theme-context'

const RadialGradientBackground: React.FC = () => {

  const startColor =  '#0284c5' 
  const endColor ='#030712' 

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background: `radial-gradient(circle, ${startColor} 0%, ${endColor} 30%)`,
        }}
      >
      </div>
    </div>
  )
}

export default RadialGradientBackground
