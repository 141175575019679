import React from 'react'

const EllipseGradientBackground: React.FC = () => {
 
  const startColor ='#0284c5' 
  const endColor = '#030712' 

  return (
    <div className="absolute top-0 left-0 size-full overflow-hidden pointer-events-none z-[-1]">
      <div
        className="absolute -left-1/2 -top-1/4 size-full opacity-50"
        style={{
          background: `radial-gradient(ellipse at center, ${startColor} 0%, ${endColor} 50%)`,
          transform: 'rotate(40deg) skew(-25deg)',
        }}
      />
    </div>
  )
}

export default EllipseGradientBackground
